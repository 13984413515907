<template>
  <form @submit.prevent="triggerLoginStoreFunction()">
    <div class="login_input">
      <label>{{ $t("Profile.Info.email") }}</label>
      <input
        class="email"
        :placeholder="$t('Profile.Info.email')"
        type="email"
        v-model="email"
        name="email"
        ref="email"
        autocomplete="on"
        required
        @invalid="InvalidMsg($event)"
        @input="InvalidMsg($event)"
      />
    </div>

    <div class="login_input">
      <label>{{ $t("Login.pwd") }}</label>

      <input
        class="password"
        :placeholder="$t('Login.pwd')"
        type="password"
        name="password"
        autocomplete="on"
        v-model="password"
        required
        @invalid="InvalidMsg($event)"
        @input="InvalidMsg($event)"
      />
    </div>

    <button class="button login_button" type="submit" id="login">
      <span v-if="waiting_for_api_response" class="loading_spinner center" style="--color : white; position: relative"></span>
      <a v-else>{{ $t("Login.login") }}</a>
    </button>
  </form>
</template>

<script>
import $ from "jquery";
import axios from "axios";

//STORE
import Vuex from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      waiting_for_api_response : false
    };
  },
  mounted() {
    //REDITUS
    /*
    try {
      (function(w, d, s, p, t) { w.gr = w.gr || function() { w.gr.q = w.gr.q || []; w.gr.q.push(arguments); }; p = d.getElementsByTagName(s)[0]; t = d.createElement(s); t.async = true; t.src = "https://app.getreditus.com/gr.js?_ce=60"; p.parentNode.insertBefore(t, p); })(window, document, "script"); gr("track", "pageview");
    } catch (e){
      console.error("REDITUS error ", e)
    }    
    */
    document.addEventListener("keydown", (e) => {
      if (e.key == "Enter") {
        $("p.button#login").trigger("click");
      }
    });

    if (this.$route.query.email != undefined) {
      this.email = this.$route.query.email;
      this.$refs.email.disabled = true;
    }
  },
  methods: {
    ...Vuex.mapActions(["login"]),
    triggerLoginStoreFunction() {
      this.waiting_for_api_response = true
      this.login({
        email: this.email,
        password: this.password,
        team_id: this.$route.query.team_id,
        query: this.$route.query,
      })
        .then((res) => {
          //REDITUS
          /*
          try {
            gr('track', 'conversion', { email: this.email });
          } catch (e){
            console.error("REDITUS error ", e)
          }
          */

          this.waiting_for_api_response = false
          if (
            res.data.usr_info.usr_language != undefined &&
            this.$i18n.locale != res.data.usr_info.usr_language
          ) {
            this.$i18n.locale = res.data.usr_info.usr_language;
            localStorage.setItem("lang", res.data.usr_info.usr_language);
          } else {
            axios.put("/users/" + res.data.usr_info._id, {
              usr_language: this.$i18n.locale,
            });
          }
        })
        .catch((err) => {
          this.waiting_for_api_response = false
          if(err == undefined){ // API OFFLINE
            this.Toaster.error(this.$t("Toaster.error.something_whent_wrong"));
          } else if (err.status == 401 && err.data == "Unauthorized user in developpement environnement"){
            this.Toaster.error(this.$t("Login.unauthorized_user_in_dev_mode"));
          } else if (err.status == 403) { //VALIDATION MODE
            if (this.email != undefined) {
              this.$parent.email = this.email;
            }
            this.$parent.mode = "validate";
          } else if (err.status == 400) {
            this.Toaster.error(this.$t("Login.invalidCredentials"));
          } else if (err.data == "Invitation not found") {
            this.Toaster.error(this.$t("Login.error.invitNotFound"));
          } else {
            this.Toaster.error(this.$t("Toaster.error.something_whent_wrong"));
          }
        });
    },
    InvalidMsg(event) {
      if (event.target.value == "") {
        event.target.setCustomValidity(this.$t("Login.validity.required"));
      } else if (event.target.validity.typeMismatch) {
        event.target.setCustomValidity(this.$t("Login.validity.emailFormat"));
      } else if (event.target.validity.tooShort) {
        event.target.setCustomValidity(this.$t("Login.validity.phoneFormat"));
      } else {
        event.target.setCustomValidity("");
      }
    },
  },
};
</script>

<style src="../login.scss" lang="scss" scoped></style>