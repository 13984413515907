<template>
  <div>
    <form @submit.prevent="
      forgotPwd(email).then(() => {
        changeMode();
      })
    " v-if="token==null">
      <h1>{{ $t("Login.forgotPwd") }}</h1>
      <h2 class="center" style="width: 100%">
        {{ $t("Login.generateNewPwdMsg") }}
      </h2>

      <div class="login_input center">
        <input type="email" name="email" :placeholder="$t('Profile.Info.email')" v-model="email" required
          @invalid="InvalidMsg($event)" @input="InvalidMsg($event)" />
      </div>

      <button class="button resetPassButton" :class="{ activeRegisterButton: activeRegisterButton }">
        {{ $t('Profile.ChangePwd.confirm') }}
      </button>
    </form>
    <section v-else class="center">
      <h1 style="width: fit-content;margin:32px auto;">{{ $t('Profile.ChangePwd.title') }}</h1>
      <password v-if="tokenValid" @finished="changeMode"></password>
      <h3 v-if="!tokenValid&&tokenValid != null" style="color: var(--color_negative); margin-bottom: 5%;">
        {{ $t("Login.forgotLinkInvalid") }}
      </h3>
    </section>
  </div>

</template>

<script>
import Vuex from "vuex";
import axios from "axios"

// Components 
import password from "../../Profile/subViews/password.vue";
export default {
  components: { password },
  name: "ForgotForm",
  data() {
    return {
      email: "",
      error: null,
      token: null,
      tokenValid: null
    };
  },
  mounted() {
    if (this.$route.query.token != undefined) {
      this.token = this.$route.query.token
      axios.get('/auth/ping', {
        headers: { Authorization: `Bearer ${this.token}` }
      })
      .then(() => this.tokenValid = true)
        .catch(() => this.tokenValid = false)
    }
  },
  methods: {
    ...Vuex.mapActions(["forgotPwd"]),
    changeMode() {
      this.$parent.mode = "login";
    },
    InvalidMsg(event) {
      if (event.target.value == "") {
        event.target.setCustomValidity(this.$t("Login.validity.required"));
      } else if (event.target.validity.typeMismatch) {
        event.target.setCustomValidity(this.$t("Login.validity.emailFormat"));
      } else if (event.target.validity.tooShort) {
        event.target.setCustomValidity(this.$t("Login.validity.phoneFormat"));
      } else {
        event.target.setCustomValidity("");
      }
    },
  },
  computed: {
    activeRegisterButton() {
      return true
    },
  },
};
</script>


<style scoped>
label {
  color: var(--color2);
  font-weight: 400;
}

h1 {
  margin-top: 20px;

}

h2 {
  font-weight: 400;
  width: 80%;
  text-align: center;
  margin-top: 10px;
}

h3,
h2,
h1 {
  text-align: center;
}

.div_mail {
  margin-top: 30px;
  min-width: 100px;
  max-width: 300px;
  width: 80%;
}

.div_mail>input {
  width: 100%;
  box-sizing: border-box;
}

.reset {
  margin-top: 30px;
}

.flexRow {
  width: 60%;
  margin-top: 20px;
  justify-content: space-between;
}
</style>


<style src="../login.scss" lang="scss" scoped>

</style>