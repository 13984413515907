<template>
  <div class="validateForm">
    <h1>{{ $t("ValidateForm.title") }}</h1>
    <p>{{ $t("ValidateForm.descri") }}</p>
    <div class="validation_code_inputs center" id="validationCodeInputs">
      <input v-for="(code_part, index) in [1, 2, 3, 4, 5, 6]" :key="`input_${index}`" :ref="`input_${index}`" type="text"
        pattern="\d*" maxlength="1" @input="moveNext($event, index)" autocomplete="off" data-lpignore="true"
        data-form-type="other" />
    </div>
    <p class="button" @click="sendVerif">{{ $t("Login.login") }}</p>

    <p class="verif">{{ $t("ValidateForm.emailNotFound") }}</p>
    <p class="verif sendVerif" @click="resendCode">
      {{ $t("ValidateForm.resend") }}
    </p>
  </div>
</template>

<script>
import $ from "jquery";

import axios from "axios";
import Vuex from "vuex";
export default {
  props: ["email"],
  name: "ValidateForm",
  data() {
    return {
      error: null,
    };
  },
  mounted() {

    //REDITUS
    /*
    try {
      (function(w, d, s, p, t) { w.gr = w.gr || function() { w.gr.q = w.gr.q || []; w.gr.q.push(arguments); }; p = d.getElementsByTagName(s)[0]; t = d.createElement(s); t.async = true; t.src = "https://app.getreditus.com/gr.js?_ce=60"; p.parentNode.insertBefore(t, p); })(window, document, "script"); gr("track", "pageview");
    } catch (e){
      console.error("REDITUS error ", e)
    }  */

    const paste = (event) => {
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      this.$refs[`input_${0}`][0].value = paste.charAt(0) || "";
      this.$refs[`input_${1}`][0].value = paste.charAt(1) || "";
      this.$refs[`input_${2}`][0].value = paste.charAt(2) || "";
      this.$refs[`input_${3}`][0].value = paste.charAt(3) || "";
      this.$refs[`input_${4}`][0].value = paste.charAt(4) || "";
      this.$refs[`input_${5}`][0].value = paste.charAt(5) || "";
      this.$refs[`input_${5}`][0].focus();
      event.preventDefault();
    };

    this.$refs[`input_${0}`][0].addEventListener("paste", paste);
    this.$refs[`input_${1}`][0].addEventListener("paste", paste);
    this.$refs[`input_${2}`][0].addEventListener("paste", paste);
    this.$refs[`input_${3}`][0].addEventListener("paste", paste);
    this.$refs[`input_${4}`][0].addEventListener("paste", paste);
    this.$refs[`input_${5}`][0].addEventListener("paste", paste);
  },
  methods: {
    ...Vuex.mapActions(["setToken"]),
    sendVerif() {
      const code = "".concat(
        /*
        //this.$refs[`input_${index + 1}`]
        this.$refs.input[0].value,
        this.$refs.input[1].value,
        this.$refs.input[2].value,
        this.$refs.input[3].value,
        this.$refs.input[4].value,
        this.$refs.input[5].value
        */

        this.$refs[`input_${0}`][0].value,
        this.$refs[`input_${1}`][0].value,
        this.$refs[`input_${2}`][0].value,
        this.$refs[`input_${3}`][0].value,
        this.$refs[`input_${4}`][0].value,
        this.$refs[`input_${5}`][0].value
      );
      axios
        .post("/auth/validation", { usr_email: this.email, code: code })
        .then((data) => {
          data = data.data;

          try {
            fpr("referral", { email: this.email });
          } catch (e) {
            console.error("Unable to referral", e)
          }

          this.setToken({
            token: data.token,
            usr_info: data.usr_info,
            query: this.$route.query,
          });
        })
        .catch((err) => {
          if (err.response.data == "Missing code") {
            this.Toaster.error(this.$t("ValidateForm.error.noCode"));
          } else if (err.response.status == 400) {
            if (err.response.data == "Expired code") {
              this.Toaster.warn(this.$t("ValidateForm.warn.expiredCode"));
            } else {
              this.Toaster.error(this.$t("ValidateForm.error.incorrectCode"));
            }
          }
        });
    },
    resendCode() {
      axios
        .post("/auth/resendCode", { usr_email: this.email })
        .then(() => {
          this.Toaster.info(this.$t("ValidateForm.info.codeResend"));
        })
        .catch(() => {
          this.Toaster.error(this.$t("ValidateForm.error.unableSendCode"));
        });
    },
    moveNext(evt, index) {
      if (evt.data != null) {
        if (index < 5) {
          this.$refs[`input_${index + 1}`][0].focus();
        }
      }
    },
  },
};
</script>


<style scoped>
.validateForm {
  width: 70%;
  text-align: center;
}

h1 {
  margin-top: 25px;
  margin-bottom: 20px;
  font-weight: 800;
  text-align: center;
}

.rowInput {
  margin-top: 25px;
  margin-bottom: 30px;
  justify-content: center;
  display: flex;
}

.verif {
  font-weight: 400;
  text-align: center;
}

.sendVerif:hover {
  text-decoration: underline;
  cursor: pointer;
}

p {
  font-weight: 400;
  margin-bottom: 15px;
  font-size: 16px;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent;
}

input::placeholder {
  color: transparent;
}
</style>

<style src="../login.scss" lang="scss" scoped></style>