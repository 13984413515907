<template>
  <form action="" @submit.prevent="triggerLoginStoreFunction()">
    <div class="halfPart">
      <div>
        <label for="">{{ $t("Profile.Info.fname") }} </label>
        <input
          type="text"
          :placeholder="$t('Profile.Info.fname')"
          v-model="firstname"
          required
          @invalid="InvalidMsg($event)"
          @input="InvalidMsg($event)"
        />
      </div>
      <div>
        <label for="">{{ $t("Profile.Info.name") }}</label>
        <input
          type="text"
          :placeholder="$t('Profile.Info.name')"
          v-model="lastname"
          required
          @invalid="InvalidMsg($event)"
          @input="InvalidMsg($event)"
        />
      </div>
    </div>
    <div>
      <label for="">{{ $t("Profile.Info.email") }}</label>
      <input
        type="email"
        :placeholder="$t('Profile.Info.email')"
        v-model="email"
        ref="email"
        required
        @invalid="InvalidMsg($event)"
        @input="InvalidMsg($event)"
      />
    </div>

    <div>
      <!--
      <div>
        <label for="">{{ $t("Profile.Info.phone") }}</label>
        <input
          type="tel"
          :placeholder="$t('Profile.Info.phone')"
          minlength="10"
          v-model="phone"
          required
          @invalid="InvalidMsg($event)"
          @input="InvalidMsg($event)"
        />
      </div>
    -->
      <div>
        <label for="">{{ $t("Profile.Info.company") }}</label>
        <input
          type="text"
          :placeholder="$t('Profile.Info.company')"
          v-model="organization"
          required
          @invalid="InvalidMsg($event)"
          @input="InvalidMsg($event)"
          autocomplete="organization"
        />
      </div>
    </div>
    <div class="halfPart">
      <div>
        <label for="">{{ $t("Login.pwd") }}</label>
        <input
          type="password"
          :placeholder="$t('Login.pwd')"
          v-model="password"
          required
          @invalid="InvalidMsg($event)"
          @input="InvalidMsg($event)"
          autocomplete="new-password"
        />
      </div>
      <div>
        <label for="">{{ $t("Login.confirmPwd") }}</label>
        <input
          type="password"
          :placeholder="$t('Login.confirmPwd')"
          v-model="confirmPwd"
          required
          @invalid="InvalidMsg($event)"
          @input="InvalidMsg($event)"
          autocomplete="new-password"
        />
        <p
          style="color: red; margin-top: 2%"
          v-if="password != '' && confirmPwd != '' && !verifPwd"
        >
          {{ $t("Profile.ChangePwd.notMatch") }}
        </p>
      </div>
    </div>

    <div class="check_terms_conditions_of_use">
     
      <label for="terms_conditions_of_use"
        >
        <input
        type="checkbox"
        id="terms_conditions_of_use"
        v-model="terms_conditions_of_use"
        style="display: inline-block; transform: translateY(1px);"
      />
      <a>{{ $t("Login.i_agree_with_terms_conditions_of_use_part_1") }}</a>
        <a
          href="https://www.yopbox.com/conditions-generales-dutilisation"
          target="_blank"
          >{{ $t("Login.i_agree_with_terms_conditions_of_use_part_2") }}</a
        ></label
      >
    </div>

    <button class="button register_button" ref="submit">
      <span v-if="waiting_for_api_response" class="loading_spinner center" style="--color : white; position: relative"></span>
      <a v-else>{{ $t("Login.register") }}</a>
    </button>
  </form>
</template>

<script>
import Vuex from "vuex";

export default {
  name: "Register",
  emits: ["verifMode"],
  data() {
    return {
      email: "",
      password: "",
      confirmPwd: "",
      firstname: "",
      lastname: "",
      //phone: "",
      organization: "",
      terms_conditions_of_use: false,
      waiting_for_api_response : false
    };
  },
  mounted() {
    if (this.$route.query.email != undefined) {
      this.email = this.$route.query.email;
      this.$refs.email.disabled = true;
    }
  },
  computed: {
    verifPwd() {
      return this.password == this.confirmPwd;
    },
  },
  methods: {
    ...Vuex.mapActions(["register"]),
    InvalidMsg(event) {
      if (event.target.value == "") {
        event.target.setCustomValidity(this.$t("Login.validity.required"));
      } else if (event.target.validity.typeMismatch) {
        event.target.setCustomValidity(this.$t("Login.validity.emailFormat"));
      } else if (event.target.validity.tooShort) {
        event.target.setCustomValidity(this.$t("Login.validity.phoneFormat"));
      } else {
        event.target.setCustomValidity("");
      }
    },
    triggerLoginStoreFunction() {
      if (!this.terms_conditions_of_use) {
        this.Toaster.error(
          this.$t("Toaster.error.accepte_terms_conditions_of_use")
        );
        return;
      }
      if (this.verifPwd) {
        this.$refs.submit.setAttribute("disabled", "");
        //SHOW LOADING SPNNIER
        this.waiting_for_api_response = true
        this.register({
          email: this.email,
          password: this.password,
          firstname: this.firstname,
          lastname: this.lastname,
          //phone: this.phone,
          organization: this.organization,
          team_id: this.$route.query.team_id,
          language: this.$i18n.locale,
        })
          .then(() => {
            this.waiting_for_api_response = false
            this.$emit("verifMode", this.email);
          })
          .catch((err) => {
            console.log(err?.response);
            this.waiting_for_api_response = false
            if (err?.response?.status == 401 && err?.response?.data == "Unauthorized user in developpement environnement"){
              this.Toaster.error(this.$t("Login.unauthorized_user_in_dev_mode"));
            } else if (err?.response && err?.response?.data == "Invitation not found") {
              this.Toaster.error(this.$t('Login.error.invitNotFound'));
            } else if (err?.response && err?.response?.data == "Email already used") {
              this.Toaster.error(this.$t('Login.error.emailUsed'));
              this.$parent.mode = "login";
              if (this.$route.query.mode != undefined) {
                this.$router.replace({
                  query: {
                    mode: "login",
                    email: this.$route.query.email,
                    team_id: this.$route.query.team_id,
                  },
                });
              }
            } else {
              this.Toaster.error(
                this.$t("Toaster.error.something_whent_wrong")
              );
            }

            this.$refs.submit.removeAttribute("disabled");
          });
      } else {
        this.Toaster.error(this.$t("Profile.ChangePwd.notMatch"));
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
form > div {
  margin-top: 14px;
}
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="password"] {
  width: 100%;
}

.halfPart {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.halfPart > div {
  width: calc(50% - 10px);
  margin-top: 6px;
}

.check_terms_conditions_of_use {
  margin-top: 30px;
}

.check_terms_conditions_of_use > label > a {
  color: var(--color2);
}
</style>


<style src="../login.scss" lang="scss" scoped></style>