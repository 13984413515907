<template>
  <div class="loginBox center" :class="`mode_${mode}`">
    <span class="shinny_banner"></span>
    <img :src='require("../../assets/" + domain_customization.full_logo_color_path)' class="logo center" @click="mode = 'login'" />

    <!-- FORMS -->
    <SignInForm class="form" v-if="mode == 'login'" />
    <RegisterForm class="form" v-if="mode == 'register'"
      @verifMode="(emailGived) => { email = emailGived; mode = 'validate' }" />
    <ForgotForm class="form" v-if="mode == 'forgot'" />
    <ValidateForm v-if="mode == 'validate'" :email="email" />

    <div class="options" v-if="mode == 'login'">
      <a @click="mode = 'forgot'">{{ $t('Login.forgotPwd') }}</a>
      <span v-if='!mobile_navigator' >|</span>
      <a @click="mode = 'register'">{{ $t('Login.noAccount') }}</a>
    </div>

    <div class="options" v-if="mode == 'register'">
      <a @click="mode = 'login'">{{ $t('Login.haveAccount') }}</a>
    </div>

    <div class="options" v-if="mode == 'forgot'">
      <a @click="mode = 'login'">{{ $t('Back') }}</a>
    </div>

  </div>
</template>


<script>

//NPM
import Vuex from "vuex";


// COMPONENTS

import RegisterForm from "./components/RegisterForm.vue";
import SignInForm from "./components/SignInForm.vue";
import ForgotForm from "./components/ForgotForm.vue";
import ValidateForm from "./components/ValidateForm.vue";

import {default as navigationVersion} from "../../../src/lib/navigatorVersion"

export default {
  name: "Login",

  components: { RegisterForm, ForgotForm, ValidateForm, SignInForm },
  data() {
    return {
      mode: "login",
      email: "",
      mobile_navigator : navigationVersion.mobile || false
    };
  },
  mounted() {
    this.$cookies.remove("token");
    if (this.$route.query.mode != undefined) {
      this.mode = this.$route.query.mode
    }

  },
  watch: {
    mode(newV, oldV) {
      if (oldV == 'forgot' && newV == 'login') {
        this.mode = 'login'
        let query = Object.assign({}, this.$route.query);
        delete query.mode;
        delete query.token;
        this.$router.replace({ query });
      }
    }
  },
  computed: {
    ...Vuex.mapGetters(["getToken"]),
  },
  beforeUnmount() {
    //IF TOKEN IS IN STORE
    // (SO AFTER A LOGIN AND JUSTE BEFORE STORE REDIRECT ROUTER TO HOME PAGE)
    // SET TOKEN IN COOKIES FOR AUTO LOGIN IN FUTURE ACCESS OR OTHER APP TAB
    if (this.getToken != null && this.getToken != undefined) {
      //this.$cookies.set("token", this.getToken);
      //console.log("process.env.TOKEN_COOKIE_DOMAIN", this.TOKEN_COOKIE_DOMAIN)
      this.$cookies.set("token", this.getToken, null, null, this.TOKEN_COOKIE_DOMAIN);
    }
  }
};
</script>


<style src="./login.scss" lang="scss" scoped>

</style>
