<template>
    <div>
        <form class="formPwd center" @submit.prevent="confirm()">
            <div v-if="token == null">
                <label>{{ $t('Profile.ChangePwd.old') }}</label>
                <input type="password" v-model="old_pwd" required />
            </div>

            <div>
                <label>{{ $t('Profile.ChangePwd.new') }}</label>
                <input type="password" v-model="new_pwd.pwd"  required/>
                <p v-if="!pwdLength && new_pwd.pwd.length != ''" class="error">
                    {{ $t('Profile.ChangePwd.tooShort') }}
                </p>
            </div>

            <div>
                <label>{{ $t('Profile.ChangePwd.confirmation') }}</label>
                <input type="password" v-model="new_pwd.confirm" required />
                <p v-if="!pwdEquals && pwdLength" class="error">
                    {{ $t('Profile.ChangePwd.notMatch') }}
                </p>
                <p v-if="pwdEquals && pwdLength" class="valid">
                    {{ $t('Profile.ChangePwd.match') }}
                </p>
            </div>
            <div>
                <input class="button confirm" :class="{disabled : !pwdEquals || !pwdLength}" type="submit" :value="$t('Profile.ChangePwd.confirm')">


            </div>

        </form>
    </div>
</template>

<script>
import axios from "axios";


import Vuex from "vuex"

//LIB
import parseJwt from "../../../lib/parseJwt";
export default {
    data() {
        return {
            old_pwd: "",
            new_pwd: {
                pwd: "",
                confirm: "",
            },
            token: null,
            tokenDecoded: null

        };
    },
    mounted() {
        if (this.$route.query.token != undefined) {
            this.token = this.$route.query.token
            this.tokenDecoded = parseJwt(this.token)
        } else {
            let formWidth = document.querySelector('.formPwd') //Pas tres beau de faire ça j'avoue
            formWidth.style.width = "35%"
        }
    },
    emits: ['finished'],
    computed: {
        ...Vuex.mapGetters(["getCurrentUsrId"]),
        pwdEquals() {
            return this.new_pwd.pwd === this.new_pwd.confirm ? true : false;
        },
        pwdLength() {
            return this.new_pwd.pwd.length > 4 ? true : false;
        },
    },
    methods: {
        confirm() {
            if (this.pwdEquals && this.pwdLength) {
                let set = {
                    usr_pwd: this.new_pwd.pwd,
                };
                let id = this.getCurrentUsrId
                let headers = undefined
                if (this.token != null) {

                    id = this.tokenDecoded.usr_id
                    headers = {
                        headers: { Authorization: `Bearer ${this.token}` }
                    };
                } else {
                    set.old_pwd = this.old_pwd
                }

                axios
                    .put("/users/" + id + "/password", set, headers)
                    .then(() => {
                        this.Toaster.success(this.$t('Profile.ChangePwd.success.pwdUpdate'))
                        this.$emit('finished')

                        this.old_pwd = ""
                        this.new_pwd = {
                            pwd: "",
                            confirm: ""
                        }
                        this.token = null
                        this.tokenDecoded = null

                    })
                    .catch((err) => {
                        if (err.response.data == "Invalid password") {
                            this.Toaster.error(this.$t('Profile.ChangePwd.error.oldPwd'))
                        } else {
                            this.Toaster.error(this.$t('Toaster.error.update'))
                        }

                    });
            }

        },
    },
};
</script>

<style>
.formPwd {
    width: 90%
}

.formPwd>div {
    margin-top: 20px;
}

.formPwd>*>input {
    width: 100%;
}

.confirm {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    width: 50%;
}

.error {
    color: var(--color_negative);
}

.valid {
    color: green;
}
</style>
